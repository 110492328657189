export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dánčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["dk"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg leder efter et lademodul til mit brand - mindst 3 tegn"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationen kan finde dig et opladningsmodul, der er kompatibelt med din maskines mærke"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationen begynder først at søge efter et kompatibelt opladningsmodul efter at have indtastet mindst tre tegn"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den elektriske maskine, du leder efter, findes i øjeblikket ikke i applikationskataloget"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mere information på WEB-siden"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vejrudsigten er ikke tilgængelig i øjeblikket !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vejrudsigt hver time"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindretning"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regnen"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindhastighed"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindstød"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lufttryk"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sner"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solopgang"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solnedgang"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solrig"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halvt klart"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["overskyet"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["næsten overskyet"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fuldstændig overskyet"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opladeren er inaktiv eller under service"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TILBAGE"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KORT"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPROG"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VEJRET"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TRÅDLØST INTERNET"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HJÆLP"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationsmenu"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne del af applikationen er midlertidigt utilgængelig"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Opladeren understøtter i øjeblikket ikke delt WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For sin funktionalitet kræver applikationen en skærm med en minimumsopløsning: bredde 800 og højde 600 pixels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Søndag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mandag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tirsdag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onsdag"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torsdag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Fredag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lørdag"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["januar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["marts"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["April"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juni"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juli"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["august"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["september"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["november"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["december"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ejeren af ​​opladeren besluttede ikke at dele det offentlige WIFI-netværk af tekniske årsager"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et delt WIFI-netværk er tilgængeligt i nærheden af ​​opladeren"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log på"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Adgangskode"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklager, vi er ved at forberede indholdet."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi beklager,~men orienteringskortet for denne oplader er ikke tilgængeligt i øjeblikket"])}
  }
}