export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gréčtina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gr"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αναζητώ μια μονάδα φόρτισης για την επωνυμία μου - τουλάχιστον 3 χαρακτήρες"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εφαρμογή μπορεί να σας βρει μια μονάδα φόρτισης συμβατή με τη μάρκα του μηχανήματος σας"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η εφαρμογή ξεκινά την αναζήτηση για μια συμβατή μονάδα φόρτισης μόνο αφού εισαγάγετε τουλάχιστον τρεις χαρακτήρες"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Το ηλεκτρικό μηχάνημα που ψάχνετε δεν βρίσκεται προς το παρόν στον κατάλογο εφαρμογών"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Περισσότερες πληροφορίες στην ΙΣΤΟΣΕΛΙΔΑ"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η πρόγνωση του καιρού δεν είναι προς το παρόν διαθέσιμη !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ωριαία πρόγνωση καιρού"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κατεύθυνση ανέμου"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Η βροχή"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΤΑΧΥΤΗΤΑ ΑΝΕΜΟΥ"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ριπές ανέμου"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πίεση αέρα"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Χιονίζει"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aνατολή ηλίου"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["η δυση του ηλιου"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hλιόλουστος"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["μισό καθαρό"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["συννεφιασμένος"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["σχεδόν συννεφιασμένο"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["εντελώς συννεφιασμένο"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο φορτιστής είναι ανενεργός ή βρίσκεται υπό σέρβις"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΠΙΣΩ"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΧΑΡΤΗΣ"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΓΛΩΣΣΕΣ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ΚΑΙΡΟΣ"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΠΛΗΡΟΦΟΡΙΕΣ"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΣΥΝΔΕΣΗ"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ΒΟΗΘΕΙΑ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μενού εφαρμογών"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αυτό το τμήμα της εφαρμογής δεν είναι προσωρινά διαθέσιμο"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο φορτιστής προς το παρόν δεν υποστηρίζει κοινόχρηστο WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Για τη λειτουργικότητά της, η εφαρμογή απαιτεί οθόνη με ελάχιστη ανάλυση: πλάτος 800 και ύψος 600 pixels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κυριακή"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δευτέρα"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τρίτη"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Τετάρτη"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Πέμπτη"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Παρασκευή"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σάββατο"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιανουάριος"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Φεβρουάριος"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Μάρτιος"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Απρίλιος"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ενδέχεται"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιούνιος"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ιούλιος"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Αύγουστος"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σεπτέμβριος"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Οκτώβριος"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Νοέμβριος"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Δεκέμβριος"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ο ιδιοκτήτης του φορτιστή αποφάσισε να μην μοιράζεται το δημόσιο δίκτυο WIFI για τεχνικούς λόγους"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ένα κοινόχρηστο δίκτυο WIFI είναι διαθέσιμο κοντά στο φορτιστή"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Σύνδεση"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Κωδικός πρόσβασης"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λυπούμαστε, ετοιμάζουμε το περιεχόμενο."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Λυπούμαστε, αλλά~ο χάρτης προσανατολισμού αυτού του φορτιστή δεν είναι διαθέσιμος αυτήν τη στιγμή"])}
  }
}