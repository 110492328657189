
import  axios       from    "axios"

import  OBJ         from    '@/assets/modules/OBJMethodsClass'
import  LIB         from    '@/assets/modules/Library'
import  DT          from    '@/assets/modules/DateTime'
import  URL         from    '@/assets/modules/URLMethodsClass'
import  SPEC        from    "@/assets/modules/Specials"

export default class dateTime {

constructor (App) {
    this.App    =   App

    this.OBJ    =   new OBJ(this.App)
    this.LIB    =   new LIB(this.App)
    this.DT     =   new DT(this.App)
    this.URL    =   new URL(this.App)
    this.SPEC    =  new SPEC(this.App)

    this.axios = 'assets/modules/Debugger.js'
}

readForDebugger =   ()  =>   {

    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-debugger')

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        //console.log(response.data)
        if(!this.SPEC.isErrorResponseData('Debugger.js (1)', formData, response, true, false))  {
            
            this.App.oDBG = response.data
            //console.log(typeof this.App.oDBG)
            //console.log(this.App.oDBG)
    
            let dbgWorkSpace    = document.getElementById('dbg-work-space')
            if(dbgWorkSpace!==null) {
        
                dbgWorkSpace.innerHTML = ''
                let inner           = '<table border="0" width="100%">'
        
                //console.log(this.App.oDBG)
                for(let oDBGLine of this.App.oDBG)   {

                    if(typeof oDBGLine.theme !== 'string')      oDBGLine.theme  =   ''
                    if(typeof oDBGLine.value !== 'string')      oDBGLine.value  =   ''


                        if(oDBGLine.theme.trim().length>0  && oDBGLine.value.trim().length>0)   {
        
                            inner += '<tr>'
                            inner += '<td style="color:#000;text-align:right;border-bottom:dotted 1px #cc0000;">'
                            inner   +=  oDBGLine.theme.replace(' ', '&nbsp;') + ':&nbsp;'
                            inner += '</td>'
                            inner += '<td style="text-align:left;border-bottom:dotted 1px #cc0000;">'
                            inner   +=  eval(oDBGLine.value)
                            inner += '</td>'                            
                            inner += '</tr>'
                        }
                    
                }
                inner += '</table>'
                dbgWorkSpace.innerHTML = inner
            }            
        }

        
        //let oDBG = response.data

        /*
        if(typeof response.data !== 'object')   return
        if(this.OBJ.count(response.data)===0)   return  
        if(typeof response.data.error === 'string')   {
            console.log(response.data)
            //send mail
            return
        }
        */

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('Debugger.js (1)', error, formData)})
}

refreshDbg  =   ()  =>  {

    this.readForDebugger()
    //this.rerender = new Date() 
}

restartApp  =   (netHeavenlyGate='')  =>  {

    //alert('Restart :: ' + netHeavenlyGate)

    let aURL = window.location.href.split('/')
    let URL = aURL[0] + '/' + aURL[1] + '/' + aURL[2]

    //console.log(netHeavenlyGate)
    //console.log(typeof netHeavenlyGate)
    if(netHeavenlyGate.trim().length===0)   {

        URL += '/?&' + localStorage.gate + '=' + localStorage.netHeavenlyGate
        URL += '&' + localStorage.net + '=' + localStorage.projectName
    }

    if(netHeavenlyGate.trim().length>0)   {
        URL += '/?&' + localStorage.gate + '=' + netHeavenlyGate
        URL += '&' + localStorage.net + '=' + localStorage.projectName
        localStorage.netHeavenlyGate = netHeavenlyGate
    }

    URL += '&timestamp=' + new Date()

    setTimeout(() => {window.location.href = URL}, 0);
    
}

openDbg =   ()  =>  {
    let dbgWindow  = document.getElementById('dbg-window')
    if(dbgWindow!==null)    {
        dbgWindow.classList.remove('display-none')
    }
}

closeDbg() {
    let dbgWindow  = document.getElementById('dbg-window')
    if(dbgWindow!==null)    {
        dbgWindow.classList.add('display-none')
    }
}

mailForDeveloper    =   ()  =>  {
    
    let currentIP       =   this.App.$store.state.oTechInfo.currentIP
    let from            =   this.App.$store.state.oCurrentCharger.mail
    let serialNo        =   this.App.$store.state.oCurrentCharger.serialNo
    let axiosPath       =   this.App.$store.state.oTechInfo.axiosPath
    let timeStamp       =   localStorage.timeStamp
    let to              =   localStorage.developerMail
    let currentURL      =   window.location.href.split('/')[2].split('.')[0]

    let subject =   'Užívateľ vzdialeného bodu (' + serialNo.substring(0,6) + ') zaslal vývojárovi obsah debuggera'

    let body    =   ''
    let comma   =   ''
    body    +=  comma + 'Identikit~' + serialNo
    comma   =   '|'
    body    +=  comma + 'IP vzdialeného bodu~' + currentIP
    body    +=  comma + 'Platforma~' + currentURL.split(':')[0]
    body    +=  comma + 'REST API~' + axiosPath
    body    +=  comma + 'Časová pečiatka~' + timeStamp
    body    +=  comma

    let mail = ''
    for(let oDBGLine of this.App.oDBG)   {
        body    +=  comma + oDBGLine.theme + '~' + eval(oDBGLine.value)
        if(typeof oDBGLine.mail!=='undefined')
            mail = oDBGLine.mail
    }
    let fields  =   '{'
    fields  +=   '"from":"'+from+'",'
    fields  +=   '"to":"'+to+'",'
    fields  +=   '"subject":"'+subject+'"}'

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-sendMail')
    formData.append('fields',   fields)
    formData.append('body',     body)

    if(mail.trim().length>0)
        formData.append('mail',     mail)
    //formData.append('URL',  1)
    
    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response=>{
        //'Debugger.js (1)', formData, response, true, false
        if(!this.SPEC.isErrorResponseData('Debugger.js (2)', formData, response, false, false))  {

            alert('Obsah debuggera bol úspešne zaslaný vývojarovi !')
            formData.append('out',  response)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('Debugger.js (2)', error, formData)})
}

}
