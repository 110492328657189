export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["macedónčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mk"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Барам модул за полнење за мојот бренд - најмалку 3 знаци"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апликацијата може да ви најде модул за полнење компатибилен со брендот на вашата машина"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Апликацијата започнува да бара компатибилен модул за полнење само откако ќе внесе најмалку три знаци"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електричната машина што ја барате моментално не е пронајдена во каталогот на апликациите"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повеќе информации на ВЕБ-страницата"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Временската прогноза моментално не е достапна !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Часовна временска прогноза"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Насока на ветерот"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дождот"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Брзина на ветерот"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Налетите на ветрот"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Воздушен притисок"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Врне снег"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["нзгрејсонце"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["зајдисонце"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cончево"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["полујасно"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["облачно"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["речиси облачно"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["целосно облачно"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полначот е неактивен или е во процес на сервисирање"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАЗАД"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАРТА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЈАЗИЦИ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВРЕМЕТО"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНФО РЕГИОН"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КОНЕКТОР"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОМОШ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Мени за апликација"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Овој дел од апликацијата е привремено недостапен"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Полначот во моментов не поддржува споделена WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За својата функционалност, апликацијата бара дисплеј со минимална резолуција: ширина 800 и висина 600 пиксели"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["недела"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["понеделник"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["вторник"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["среда"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["четврток"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["петок"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сабота"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["јануари"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["февруари"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["март"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["април"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мај"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["јуни"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["јули"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["август"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["септември"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["октомври"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ноември"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["декември"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сопственикот на полначот одлучил да не ја споделува јавната WIFI мрежа од технички причини"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Достапна е споделена WIFI мрежа во близина на полначот"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Логирај Се"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Лозинка"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Извинете, ја подготвуваме содржината."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Жал ни е,~но картата за ориентација за овој полнач не е достапна во моментов"])}
  }
}