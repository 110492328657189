export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["estónčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ee"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsin oma kaubamärgile laadimismoodulit - vähemalt 3 tähemärki"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakendus leiab teile teie masina kaubamärgiga ühilduva laadimismooduli"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakendus hakkab ühilduvat laadimismoodulit otsima alles pärast vähemalt kolme tähemärgi sisestamist"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Otsitavat elektrimasinat praegu rakenduste kataloogis ei leidu"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Täpsem info VEEB-lehel"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ilmateade pole hetkel saadaval !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tunni ilmateade"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuule suund"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vihm"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuule kiirus"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tuulepuhangud"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Õhurõhk"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajab lund"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päikesetõus"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päikeseloojang"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["päikeseline"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pool selge"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pilvine"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["peaaegu pilvine"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["täiesti pilvine"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadija on passiivne või seda hooldatakse"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TAGASI"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAART"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KEELED"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ILM"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO PIIRKOND"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ÜHENDUS"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ABI"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rakenduse menüü"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["See rakenduse osa pole ajutiselt saadaval"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadija ei toeta praegu jagatud WIFI-d"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oma funktsionaalsuse tagamiseks vajab rakendus minimaalse eraldusvõimega ekraani: laius 800 ja kõrgus 600 pikslit"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pühapäev"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["esmaspäev"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teisipäeval"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kolmapäeval"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neljapäeval"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["reedel"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["laupäeval"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaanuaril"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["veebruar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["märtsil"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["aprill"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mai"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juunini"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juulil"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["august"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["septembril"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktoober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["novembril"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["detsembril"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadija omanik otsustas tehnilistel põhjustel avalikku WIFI võrku mitte jagada"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laadija lähedal on ühine WIFI-võrk"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logi sisse"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parool"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kahjuks valmistame sisu ette."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kahjuks pole~selle laadija orientatsioonikaart hetkel saadaval"])}
  }
}