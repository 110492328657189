export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Litovčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lt"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ieškau savo prekės ženklo įkrovimo modulio - ne mažiau 3 simbolių"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa gali rasti įkrovimo modulį, suderinamą su jūsų įrenginio prekės ženklu"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programa pradeda ieškoti suderinamo įkrovimo modulio tik įvedusi bent tris simbolius"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elektros mašinos, kurios ieškote, šiuo metu programų kataloge nerasta"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Daugiau informacijos WEB puslapyje"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orų prognozės šiuo metu nėra !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Orų prognozė valandai"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vėjo kryptis"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lietus"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vėjo greitis"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vėjo gūsiai"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oro slėgis"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sninga"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saulėtekis"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saulėlydis"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["saulėta"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["pusiau aiškus"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["debesuota"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["beveik debesuota"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["visiškai debesuota"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Įkroviklis neaktyvus arba yra aptarnaujamas"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATGAL"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ŽEMĖLAPIS"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KALBOS"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ORAS"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGIONAS"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BEVIELIS INTERNETAS"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["JUNGTIS"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["PAGALBA"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programos meniu"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ši programos dalis laikinai nepasiekiama"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Šiuo metu įkroviklis nepalaiko bendrinamo WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kad programa veiktų, jai reikalingas minimalios skiriamosios gebos ekranas: plotis 800 ir aukštis 600 pikselių"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sekmadienis"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pirmadienis"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["antradienis"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["trečiadienį"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ketvirtadienis"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["penktadienis"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["šeštadienis"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sausio mėn"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["vasario mėn"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kovas"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Balandis"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gegužė"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["birželis"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["liepos mėn"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rugpjūtis"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["rugsėjis"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Spalio mėn"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lapkritis"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gruodį"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiprašome, mes ruošiame turinį."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Atsiprašome,~bet šio įkroviklio orientacinio žemėlapio šiuo metu nėra"])}
  }
}