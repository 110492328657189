export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slovenčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sk"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadám nabíjací modul pre moju značku - aspoň 3 znaky"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia Vám môže nájsť nabíjací modul, kompatibilný so značkou Vášho stroja"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia začne hľadať kompatibilný nabíjací modul až po zadaní aspoň troch znakov"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hľadaný elektrický stroj sa v katalógu aplikácie v súčasnosti nenachádza"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viac informácii na WEB stránke"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predpoveď počasia nie je v súčasnosti dostupná !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Predpoveď počasia po hodinách"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Smer vetra"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dážď"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rýchlosť vetra"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nárazy vetra"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tlak vzduchu"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneženie"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["východ slnka"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["západ slnka"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["slnečno"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["polojasno"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oblačno"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["takmer zamračené"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["úplne zamračené"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíjačka nie je aktívna, alebo prebieha servis"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPÄŤ"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["jaZYky"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POČASIE"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["InfO REgIÓN"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONEKTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["POMOC"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menu aplikácie"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Táto časť aplikácie je dočasne nedostupná"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nabíjačka v súčasnosti zdieľanú WIFI nepodporuje"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikácia pre svoju funkčnosť vyžaduje display s minimálnym rozlíšením: šírka 800 a výška 600 pixelov"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nedeľa"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pondelok"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Utorok"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Streda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Štvrtok"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Piatok"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sobota"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Január"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Február"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mARec"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Apríl"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Máj"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jún"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Júl"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["September"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Október"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vlastník nabíjačky sa z technických dôvodov rozhodol nezdieľať verejnú WIFI sieť"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["V blízkosti nabíjačky je k dispozícii zdieľaná WIFI sieť"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Prihlásenie"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Heslo"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ospravedlňujeme sa, obsah pripravujeme."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ospravedlňujeme sa, ale orientačná mapa pri tejto nabíjačke nie je v súčasnosti dostupná"])}
  }
}