export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["turečtina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tr"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Markam için şarj modülü arıyorum - en az 3 karakter"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama size makinenizin markasına uygun şarj modülünü bulabilir"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama ancak en az üç karakter girdikten sonra uyumlu bir şarj modülü aramaya başlar"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aradığınız elektrikli makine şu anda uygulama kataloğunda bulunmuyor"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WEB sayfasında daha fazla bilgi"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava tahmini şu anda kullanılamıyor !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saatlik hava tahmini"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rüzgar yönü"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Yağmur"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rüzgar hızı"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rüzgar esintileri"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hava basıncı"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kar yağışı"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gündoğumu"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["gün batımı"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["güneşli"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yarı açık"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulutlu"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["neredeyse bulutlu"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tamamen bulutlu"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şarj cihazı devre dışı değil veya servise gidiyor"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["GERİ"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HARİTA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["DİLLER"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HAVA"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["BİLGİ BÖLGE"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wİ-Fİ"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONNEKTÖR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["YARDIM"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama menüsü"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulamanın bu kısmı geçici olarak kullanılamıyor"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şarj cihazı şu anda paylaşılan WIFI'ı desteklemiyor"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Uygulama, işlevselliği için minimum çözünürlüğe sahip bir ekran gerektirir: genişlik 800 ve yükseklik 600 piksel"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pazar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pazartesi"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Salı"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Çarşamba"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Perşembe"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cuma"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cumartesi"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ocak"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şubat"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mart"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nisan"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayıs"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Haziran"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Temmuz"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ağustos"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eylül"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ekim"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kasım"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aralık"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şarj cihazının sahibi, teknik nedenlerle halka açık WIFI ağını paylaşmamaya karar verdi"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şarj cihazının yakınında paylaşılan bir WIFI ağı mevcuttur"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giriş yapmak"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Şifre"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Özür dileriz, içeriği hazırlıyoruz."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Üzgünüz,~ancak bu şarj cihazının oryantasyon haritası şu anda mevcut değil"])}
  }
}