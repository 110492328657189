<template>
<div>
    <div    class="tech-info">

        <span   class="cursor col-yellow"
                @click="DBG.openDbg"
                title="Otvoriť debugger so zobrazením hodnôt nastavených vývojárom">

                <img style="width:15px;" :src="pathIcons+'orange/info-dbg-orange.svg'" alt=""  />
        </span>


        <!---
        <i  class="fa-solid fa-circle-info cursor col-yellow"   
            @click="DBG.openDbg"
            title="Otvoriť debugger so zobrazením hodnôt nastavených vývojárom"   />
            -->
        &nbsp;-&nbsp;
        <span :title="'Aktuálna IP adresa vzdialeného bodu: '+ currentIP">
            IP: {{currentIP}}
        </span>
        &nbsp;-&nbsp;
        <span :title="'Šírka a výška aktuálne používaného display:' + screenWidth.replace('px', '') + ' x ' + screenHeight.replace('px', '')">
            [{{ screenWidth.replace('px', '') + ' x ' + screenHeight.replace('px', '')  }}]
        </span> 
        &nbsp;-&nbsp;

        <!--v-if="development==true || realLocalhost==true"--->
        <span  id="serial-click" class="cursor display-none"  @click="changeSerialNo()" :title="'Poradové číslo aplikácie podľa jej identikitu: '+serialNo.substring(0,6) ">
            [(++):{{ serialNo.substring(0,6) }}]
        </span>

        <!--v-else--->
        <span id="serial-no-click"  class="display-none"  :title="'Poradové číslo aplikácie podľa jej identikitu: '+serialNo.substring(0,6) ">
            [{{ serialNo.substring(0,6) }}]
        </span>

        &nbsp;-&nbsp;
        <span :title="'Aplikácia pracuje na platforme: ' + currentURL.split(':')[0]">
            [{{currentURL.split(':')[0]}}]
        </span>

        <span v-if="userAgentNav.indexOf('OPR/')>(-1)">
            &nbsp;-&nbsp;
            <span :title="'Platforma na strane klienta / vzdialeného bodu: ' +userAgentNav">
                [{{ userAgentNav.substring(0,7) }}]
            </span>        
        </span>

        <span v-else>
            &nbsp;-&nbsp;
            <span :title="'Platforma na strane klienta / vzdialeného bodu: desktop'">
                [desktop]
            </span>                    
        </span>

        &nbsp;-&nbsp;
        <span :title="'Aplikácia aktuálne používa rozhranie REST API: ' +rest_API">
            [{{ rest_API }}]
        </span>
        &nbsp;-&nbsp;
        <span :title="'Aktuálne vygenerovaná časová pečiatka aplikácie: ' + aStamp[0] + ' ' + aStamp[1] + ' ' + aStamp[2] + ' ' + aStamp[3] + ' ' + aStamp[4]">
            [{{ aStamp[0] + ' ' + aStamp[1] + ' ' + aStamp[2] + ' ' + aStamp[3] + ' ' + aStamp[4]}}]
        </span>
        &nbsp;-&nbsp;
        <span :title="'Systémový názov projektu je: '+projectName">
            [{{ projectName}}]
        </span>
        &nbsp;-&nbsp;
        <span title="Interval v minútach návrat k úvodu aplikácie s druhým údajom kontroly dát tiež v minútach">
            [{{ (devGlobalInt)/60000 + ' m : '  +  (devControlInt)/60000 + ' m'}}]
        </span>
        &nbsp;-&nbsp;        
        <span id="watcher" title="Posledná kontrola dát " > 
            {{ DT.timeFromTimeStamp() }}
        </span>
            
        &nbsp;-&nbsp;        
        <span @click="DBG.restartApp('')"   class="cursor col-yellow" title="Reinštalovať a reštartovať túto aplikáciu">
            <img style="width:15px;" :src="pathIcons+'orange/restart-dbg-orange.svg'" alt=""  />
        </span>
        <!---
        <i  class="fa-solid fa-power-off cursor"  
            @click="DBG.restartApp('')"  
            title="Reinštalovať a reštartovať túto aplikáciu"    /> 
        -->
    </div>
</div>
</template>

<script>
    import DBG              from "@/assets/modules/Debugger"
    import DT               from "@/assets/modules/DateTime"
    import LIB              from "@/assets/modules/Library"
    import OBJ              from "@/assets/modules/OBJMethodsClass"

    export default {
        data() {
            return {
                DBG:                    new DBG(this),
                DT:                     new DT(this),
                LIB:                    new LIB(this),
                OBJ:                    new OBJ(this),
                

                pathIcons:              localStorage.pathIcons,

                development:            localStorage.development,
                realLocalhost:          localStorage.realLocalhost,

                oTechInfo:              this.$store.state.oTechInfo,

                screenWidth:            screen.width + 'px',
                screenHeight:           screen.height + 'px',
                top:                    (screen.height-25) + 'px',
                height:                 '25px',
                
                madeIn:                 localStorage.madeIn,
                currentURL:             window.location.href.split('/')[2].split('.')[0],
                aStamp:                 localStorage.timeStamp.split(' '),
                rest_API:               localStorage.restAPI .split('/')[2].split('.')[0],
                userAgentNav:           navigator.userAgent,

                projectName:            localStorage.projectName,

                devGlobalInt:           localStorage.devGlobalInt,
                devControlInt:          localStorage.devControlInt,

                oChargers:              this.$store.state.oAllChargers,
                
            }
        },
        props: {
            adminIP: {
                type: String,
                default: '',
            },  
            currentIP: {
                type: String,
                default: '',
            },  
            serialNo: {
                type: String,
                default: '',
            },  
            restAPI: {
                type: String,
                default: '',
            },  
            techInfo: {
                type: Number,
                default: 0,
            },  
        },
        methods: {
            changeSerialNo()    {

                let oCharger, oNextCharger = [], next = false 
                let netHeavenlyGate = localStorage.netHeavenlyGate

                for(oCharger of this.oChargers) {
                    if(next)    {
                        next = false
                        oNextCharger = oCharger
                        break
                    }
                    if(netHeavenlyGate.trim()===oCharger.chg_serialNo.trim())    
                        next = true
                }

                if(this.OBJ.count(oNextCharger)===0)    
                    oNextCharger = this.oChargers[0]

                this.DBG.restartApp(oNextCharger.chg_serialNo)    
                


                //  this.$store.state.[...]

                /*
                console.log(localStorage.netHeavenlyGate)

                if(localStorage.netHeavenlyGate==='99997xQQc7DV6RukzI570W0OoF')
                    this.DBG.restartApp('99998xO0wA6N4lgro25wjUz7Yf')

                if(localStorage.netHeavenlyGate==='99998xO0wA6N4lgro25wjUz7Yf')
                    this.DBG.restartApp('99999xXEIH322KH6ZQ7263rGvm')

                if(localStorage.netHeavenlyGate==='99999xXEIH322KH6ZQ7263rGvm')
                    this.DBG.restartApp('99997xQQc7DV6RukzI570W0OoF')
                */
            }
        },
        mounted () {

            if(this.userAgentNav.indexOf('OPR/')>(-1))   
                this.top=(screen.height-140) + 'px'

            let eSerialClick    =  document.getElementById('serial-click')
            let eSerialNoClick  =  document.getElementById('serial-no-click')
            //this.development || this.realLocalhost

            if( this.projectName.trim().toLowerCase()==='tester' ||
                this.projectName.trim().toLowerCase()==='local')  {

                    if(eSerialClick!==null) 
                        eSerialClick.classList.remove('display-none')                    
            }   else    {
                
                if(eSerialNoClick!==null) 
                    eSerialNoClick.classList.remove('display-none')                
            }


        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";

$screenWidth:v-bind(screenWidth);
$top:v-bind(top);
$height:v-bind(height);
.tech-info  {
    z-index:10000;
    position: absolute;
    top:$top;
    left:0%;
    background-color: #008000;
    opacity:.7;
    color:orange;
    font-family:tahoma;
    font-size:.7em;
    font-weight:600;
    width:100%;
    text-align:center;
    line-height:$height;
    height:$height;
  }
  .cursor   {
    cursor: pointer;
  }

</style>