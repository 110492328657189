export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["španielčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["es"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Estoy buscando un módulo de carga para mi marca - al menos 3 caracteres"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación puede encontrarte un módulo de carga compatible con la marca de tu máquina"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La aplicación comienza a buscar un módulo de carga compatible solo después de ingresar al menos tres caracteres"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La máquina eléctrica que buscas no se encuentra actualmente en el catálogo de aplicaciones"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Más información en la página WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["¡El pronóstico del tiempo no está disponible actualmente !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pronóstico del tiempo por hora"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dirección del viento"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La lluvia"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocidad del viento"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ráfagas de viento"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Presión del aire"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nevando"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["amanecer"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["atardecer"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soleado"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["medio claro"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nublado"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["casi nublado"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completamente nublado"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cargador está inactivo o en servicio"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ATRÁS"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["MAPA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IDIOMAS"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["EL CLIMA"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["REGIÓN"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONECTOR"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AYUDA"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menú de aplicaciones"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Esta parte de la aplicación no está disponible temporalmente"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El cargador actualmente no es compatible con WIFI compartido"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Para su funcionalidad, la aplicación requiere una pantalla con una resolución mínima: ancho 800 y alto 600 píxeles"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domingo"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunes"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martes"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miércoles"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jueves"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Viernes"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sábado"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Enero"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Febrero"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Abril"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mayo"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Junio"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Julio"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["August"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Septiembre"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Octubre"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Noviembre"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Diciembre"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["El dueño del cargador decidió no compartir la red WIFI pública por razones técnicas"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Una red WIFI compartida está disponible cerca del cargador"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Acceso"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Contraseña"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos, estamos preparando el contenido."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lo sentimos,~pero el mapa de orientación de este cargador no está disponible en este momento"])}
  }
}