<template>
    <div id="dbg-window"    class="dbg-window display-none" :key="rerender">
        <div class="dbg-head">
            <div class="dbg-head-1" >


                <img    :src="pathIcons + 'white/righttfrombracket-white.svg'" 
                        @click="DBG.closeDbg"       
                        title="Zavrieť okno debuggera"
                        style="width:25px"
                        class="cursor"
                        alt=""  />

                <!---
                <i  class="fa-solid fa-right-from-bracket cursor icon"   
                    @click="DBG.closeDbg"   
                    title="Zavrieť okno debuggera"  />
                    -->
            </div>
            <div class="dbg-head-2" >

                <img    :src="pathIcons + 'white/arrowsrotate-white.svg'" 
                        @click="DBG.refreshDbg"   
                        title="Znovu načítať okno debuggera"
                        style="width:25px"
                        class="cursor"
                        alt=""  />

                <!---
                <i  class="fa-solid fa-arrows-rotate cursor icon"    
                    @click="DBG.refreshDbg"   
                    title="Znovu načítať okno debuggera"    />
                -->
            </div>
            <div class="dbg-head-3" >
                <img    :src="pathIcons + 'white/envelopecirclecheck-white.svg'" 
                        @click="DBG.mailForDeveloper"   
                        class="cursor"
                        style="width:25px"
                        title="Zaslať obsah debuggera vývojárovi"
                        alt=""  />
                <!--
                <i  class="fa-solid fa-envelope-circle-check cursor icon"    
                    @click="DBG.mailForDeveloper"   
                    title="Zaslať obsah debuggera vývojárovi"   />
                -->
            </div>            
            <div class="dbg-head-4" >

                <img    :src="pathIcons + 'white/poweroff-white.svg'" 
                        @click="DBG.restartApp"  
                        class="cursor"
                        title="Reinštalovať a reštartovať túto aplikáciu"    
                        style="width:25px"
                        alt=""    />
                <!--
                <i  class="fa-solid fa-power-off cursor icon"  
                    @click="DBG.restartApp"  
                    title="Reinštalovať a reštartovať túto aplikáciu"    />             
                -->
            </div>            

        </div>
        <div id="dbg-work-space" class="dbg-work-space" />
    </div>
</template>

<script>
    import  DBG         from    '@/assets/modules/Debugger'

    export default {
        data() {
            return {
                DBG:                new DBG(this),

                pathIcons:          localStorage.pathIcons,

                screenWidth:        screen.width,
                width:              (Math.round(screen.width/4))*3 + 'px',
                height:             (screen.height) + 'px',
                top:                '0px',
                left:               (Math.round(screen.width/4))*1 + 'px',

                oCurrentCharger:    this.$store.state.oCurrentCharger,
                oTechInfo:          this.$store.state.oTechInfo,
                oSliders:           this.$store.state.oSliders,

                oDBG:               [],

                rerender:           '',
            }
        },  
        methods: {},
        mounted () {
            this.DBG.readForDebugger()
        },
    }
</script>

<style lang="scss" scoped>
@import "@/assets/global-styles.scss";
$top:v-bind(top);
$left:v-bind(left);
$width:v-bind(width);
$height:v-bind(height);
$screenWidth:v-bind(screenWidth);
.dbg-window   {
    //border:solid 5px blue;
    background-color:silver;
    opacity:.9;
    position: absolute;
    top:$top;
    left:$left;
    width: $width;
    height:$height;
    z-index:10000;
}
.dbg-work-space {
    padding: 10px;
    color:#cc0000;
    font-weight: 600;
    font-family: Tahoma;
    font-size:.9em;
    line-height:40px;
}
.dbg-head   {
    background-color: #000;
    height:40px;
    color:#fff;
    font-weight: 700;
    font-size:1em;
    line-height:40px;
}
.dbg-head-1 {
    float: left;
    border:solid 0px red;
    width:10%;
    text-align: center;
}
.dbg-head-2, .dbg-head-3, .dbg-head-4 {
    float: left;
    border-left:solid 1px #fff;
    width:10%;
    text-align: center;
}
.icon:hover {
    color:yellow;
}
</style>