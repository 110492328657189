export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nórčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["no"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jeg ser etter en lademodul for merket mitt - minst 3 tegn"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonen kan finne en lademodul som er kompatibel med merket til maskinen din"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonen begynner å søke etter en kompatibel lademodul først etter å ha skrevet inn minst tre tegn"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den elektriske maskinen du leter etter finnes for øyeblikket ikke i applikasjonskatalogen"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer informasjon på WEB-siden"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værmeldingen er for øyeblikket ikke tilgjengelig !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Værmelding per time"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindretning"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regnet"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindfart"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindkast"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lufttrykk"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snøer"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soloppgang"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solnedgang"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solfylt"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halvklart"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["skyet"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nesten overskyet"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helt overskyet"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laderen er inaktiv eller under service"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TILBAKE"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KART"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPRÅK"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VÆRET"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HJELP"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikasjonsmeny"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Denne delen av applikasjonen er midlertidig utilgjengelig"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laderen støtter for øyeblikket ikke delt WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["For funksjonaliteten krever applikasjonen en skjerm med minimumsoppløsning: bredde 800 og høyde 600 piksler"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["søndag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mandag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tirsdag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onsdag"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Torsdag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fredag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lørdag"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["januar"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februar"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mars"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["april"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kan"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juni"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juli"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["august"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["september"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["november"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["desember"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Eieren av laderen bestemte seg for ikke å dele det offentlige WIFI-nettverket av tekniske årsaker"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Et delt WIFI-nettverk er tilgjengelig i nærheten av laderen"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logg Inn"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Passord"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Beklager, vi forbereder innholdet."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi beklager,~men orienteringskartet for denne laderen er ikke tilgjengelig for øyeblikket"])}
  }
}