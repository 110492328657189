export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["taliančina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["it"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cerco un modulo di ricarica per il mio marchio - almeno 3 caratteri"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'applicazione può trovarti un modulo di ricarica compatibile con la marca della tua macchina"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["L'applicazione avvia la ricerca di un modulo di ricarica compatibile solo dopo aver inserito almeno tre caratteri"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La macchina elettrica che stai cercando attualmente non è presente nel catalogo delle applicazioni"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggiori informazioni sulla pagina WEB"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Le previsioni del tempo non sono al momento disponibili !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Previsioni meteo orarie"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La direzione del vento"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["La pioggia"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Velocità del vento"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Raffiche di vento"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pressione dell'aria"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Nevicando"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["alba"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tramonto"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soleggiato"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["mezzo chiaro"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nuvoloso"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["quasi nuvoloso"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["completamente nuvoloso"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il caricabatterie è inattivo o in fase di manutenzione"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INDIETRO"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CARTA GEOGRAFICA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["LE LINGUE"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["IL TEMPO"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGIONE"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CONNETTORE"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AIUTO"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Menù dell'applicazione"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Questa parte dell'applicazione è temporaneamente non disponibile"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il caricabatterie attualmente non supporta il WIFI condiviso"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Per la sua funzionalità, l'applicazione richiede un display con una risoluzione minima: larghezza 800 e altezza 600 pixel"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Domenica"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lunedi"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Martedì"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mercoledì"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giovedì"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Venerdì"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sabato"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gennaio"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Febbraio"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Marzo"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aprile"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maggio"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Giugno"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luglio"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Agosto"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Settembre"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ottobre"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Novembre"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dicembre"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Il proprietario del caricabatterie ha deciso di non condividere la rete WIFI pubblica per motivi tecnici"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vicino al caricabatterie è disponibile una rete WIFI condivisa"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Login"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Parola d'ordine"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo spiacenti, stiamo preparando il contenuto."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Siamo spiacenti,~ma la mappa di orientamento per questo caricabatterie non è al momento disponibile"])}
  }
}