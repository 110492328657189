//
import { createRouter, createWebHistory } from 'vue-router'
//import HomeView from '../views/HomeViewClock.vue'
//import econecVideo from '../views/VideoEconec.vue'
//import menuChargers from '../views/MenuChargers.vue'
//import mapSector from '../views/MapaSector.vue'
//import infoRegion from '../views/InfoRegion.vue'
//import setLocales from '../views/SetLocales.vue'
//import viewConnectors from '../views/ViewConnectors.vue'
//import viewWeather from '../views/ViewWeather.vue'
//import freeWiFi from '../views/FreeWiFi.vue'
//import getHelp from '../views/GetHelpText.vue'



const routes = [
  {
    path: '/',
    name: 'home',
    //component: HomeView
    component: () => import( '../views/../views/HomeViewClock.vue')    
  },
  {
    path: '/video',
    name: 'video',
    //component: econecVideo
    component: () => import( '../views/../views/VideoEconec.vue')    
  },
  {
    path: '/menu',
    name: 'menu',
    //component: menuChargers
    component: () => import( '../views/../views/MenuChargers.vue')    
  },
  {
    path: '/mapa',
    name: 'map',
    //component: mapSector
    component: () => import( '../views/ViewOrientationMap.vue')    
  },
  {
    path: '/info',
    name: 'infoRegion',
    //component: infoRegion
    component: () => import( '../views/ViewInfoRegion.vue')    
  },
  {
    path: '/locales',
    name: 'setLocales',
    //component: setLocales
    component: () => import( '../views/../views/ViewSetLocales.vue')    
  },
  {
    path: '/connect',
    name: 'viewConnectors',
    //component: viewConnectors
    component: () => import( '../views/ViewConnectorsInfo.vue')    

  },
  {
    path: '/weath',
    name: 'viewWeather',
    //component: viewWeather
    component: () => import( '../views/../views/ViewWeather.vue')    
  },
  {
    path: '/wifi',
    name: 'freeWiFi',
    //component: freeWiFi
    component: () => import( '../views/ViewWifiSet.vue')    
  },
  {
    path: '/help',
    name: 'getHelp',
    //component: getHelp
    component: () => import( '../views/../views/ViewHelpForApp.vue')    
  },
  {
    path: '/follow',
    name: 'followMe',
    //component: getHelp
    component: () => import( '../views/../views/FollowOurWeb.vue')    
  }
  
  
  /*
  ,
  {
    path: '/video',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.7
    component: () => import( '../views/______AboutView.vue')
  }
  */
 /* webpackChunkName: "about" */
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
