export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bieloruština"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["by"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шукаю зарадны модуль для сваёй маркі - мінімум 3 знака"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прыкладанне можа знайсці для вас модуль зарадкі, сумяшчальны з маркай вашай машыны"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прыкладанне пачынае пошук сумяшчальнага модуля зарадкі толькі пасля ўводу не менш за тры знакаў"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Электрычная машына, якую вы шукаеце, зараз не знойдзена ў каталогу прыкладанняў"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Больш інфармацыі на WEB-старонцы"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прагноз надвор'я пакуль недаступны !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пагадзінны прагноз надвор'я"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Напрамак ветру"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дождж"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Хуткасць ветру"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парывы ​​ветру"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ціск паветра"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ідзе снег"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["yсход сонца"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["закат"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cонечна"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напалову ясна"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["пахмурна"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["амаль воблачна"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["цалкам воблачна"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарадная прылада неактыўная або знаходзіцца на абслугоўванні"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАЗАД"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАРТА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["МОВЫ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["НАДВОР'Е"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ІНФАРМАЦЫЯ"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["РАЗ'ЕМ"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ДАПАМОГА"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню прыкладання"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Гэтая частка прыкладання часова недаступная"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарадная прылада зараз не падтрымлівае агульны WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Для функцыянальнасці прыкладання патрабуецца дысплей з мінімальным дазволам: шырыня 800 і вышыня 600 пікселяў"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нядзеля"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["панядзелак"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["аўторак"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["серада"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["чацвер"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пятніца"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["субота"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Студзень"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["люты"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["сакавік"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Красавік"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["мая"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Чэрвень"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ліпень"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["жнівень"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["верасень"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["кастрычніка"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["лістапад"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Снежань"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Уладальнік зараднай прылады вырашыў не выкарыстоўваць агульнадаступную сетку WIFI па тэхнічных прычынах"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Агульная сетка WIFI даступная побач з зараднай прыладай"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Увайсці"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пароль"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прабачце, мы рыхтуем кантэнт."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Нам вельмі шкада,~але карта арыентацыі для гэтай зараднай прылады зараз недаступная"])}
  }
}