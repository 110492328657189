export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["švédčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["se"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jag letar efter en laddningsmodul för mitt varumärke - minst 3 tecken"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationen kan hitta en laddningsmodul som är kompatibel med din maskins märke"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationen börjar söka efter en kompatibel laddningsmodul först efter att ha angett minst tre tecken"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den elektriska maskinen du letar efter finns för närvarande inte i applikationskatalogen"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mer information på WEB-sidan"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väderprognosen är inte tillgänglig just nu !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Väderprognos varje timme"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindriktning"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regnet"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindhastighet"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vindbyar"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lufttryck"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snöar"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["soluppgång"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solnedgång"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["solig"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["halvklart"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["molnig"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nästan molnigt"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helt molnigt"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddaren är inte inaktiv eller pågår service"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TILLBAKA"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KARTA"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SPRÅK"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VÄDRET"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGION"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KONTAKT"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HJÄLP"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Applikationsmeny"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Den här delen av applikationen är tillfälligt otillgänglig"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Laddaren stöder för närvarande inte delat WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["För sin funktionalitet kräver applikationen en skärm med minsta upplösning: bredd 800 och höjd 600 pixlar"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["söndag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["måndag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["tisdag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["onsdag"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["torsdag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["fredag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["lördag"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["januari"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["februari"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Mars"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["april"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maj"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juni"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juli"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["augusti"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["september"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["november"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["december"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ägaren av laddaren beslutade att inte dela det offentliga WIFI-nätverket av tekniska skäl"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ett delat WIFI-nätverk finns nära laddaren"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Logga in"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lösenord"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Tyvärr, vi förbereder innehållet."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vi är ledsna,~men orienteringskartan för denna laddare är inte tillgänglig för tillfället"])}
  }
}