
import axios    from    "axios"
import OBJ      from    '@/assets/modules/OBJMethodsClass'
import LIB      from    '@/assets/modules/Library'
import DT       from    '@/assets/modules/DateTime'
import SPEC     from    '@/assets/modules/Specials'

export default class AsyncData {

constructor(App) {

    this.App            =   App

    this.OBJ            =   new OBJ(this.App)
    this.LIB            =   new LIB(this.App)
    this.DT             =   new DT(this.App)
    this.SPEC           =   new SPEC(this.App)
    
    this.countFlags     =   0

    //this.viewResponse   =   true

    this.viewStartFunc  =   false   //  true
    this.viewRestart    =   false   //  true
    this.viewChargers   =   false   //  true
    this.viewFlags      =   false   //  true
    this.viewSliders    =   false   //  true
    this.viewCMS        =   false   //  true

    this.axios = 'assets/modules/AsyncData.js'
}



//===================================================================
chargerDataRead   =   ()  =>  {
    if(this.viewStartFunc)  console.log('START chargerDataRead('+new Date()+')')

    if(typeof this.App.controlInterval === 'undefined') {
        this.App.controlInterval =  setInterval(() => this.chargerDataRead(), localStorage.devControlInt)
    }

    if(typeof this.App.globalInterval === 'undefined') {
        this.App.globalInterval  =  setInterval(() => this.App.comeBackEvents_app(2), localStorage.devGlobalInt)
    }
    
    let test = window.location.href.split('/')
    
    if(test[3].trim().length > 0 && test[3].indexOf('net-heavenly-gate')===(-1))  return

    if(localStorage.timeAsyncReadData===this.DT.stdFromJSDateTime(new Date(), true, false)) return
    localStorage.timeAsyncReadData = this.DT.stdFromJSDateTime(new Date(), true, false)

    //console.log('ASYNC :: '+localStorage.timeAsyncReadData)
    //console.log(this.DT.stdFromJSDateTime(new Date(), true, false))

    console.log('A WATCH TOWER : ' + this.DT.timeFromTimeStamp())
    if(this.viewStartFunc)  console.log('WATCH TOWER : ' + this.DT.timeFromTimeStamp())

    let eWatcher = document.getElementById('watcher')
    if(eWatcher!==null) {
        eWatcher.innerHTML = this.DT.timeFromTimeStamp()
    }

    let formData = this.SPEC.formData(this.axios)
    //formData.append('command', 'client')  to do remove
    formData.append('command',      'proc-connectRemotePoint')
    formData.append('useAPI',       localStorage.useAPI)
    //formData.append('currentTime',  this.DT.timeFromTimeStamp())
    formData.append('dateTime',  this.DT.stdFromJSDateTime(new Date(), true, true))
    console.log(this.DT.stdFromJSDateTime(new Date(), true, true))

   
    let oCharger

    var App = this.App
    //new SPEC(App).axiosErrorCatch('AsyncData.js (1)', 'Toto je simulovaná chyba', formData)

    axios.post(localStorage.restAPI, formData).then(response => {

        
        if(typeof response.data['serialNo'] === 'string')   
            localStorage.substitutionSerialNo  = response.data['serialNo']

        let requiredObject = true, mustBeGreaterThanZero = true
        if(localStorage.projectName.trim().toLowerCase()==='local')   {
            //requiredObject = false
            //mustBeGreaterThanZero = false
        }
            
        if(!this.SPEC.isErrorResponseData('AsyncData.js (1)', formData, response, requiredObject, mustBeGreaterThanZero ))  {
            
            oCharger    =   response.data
            this.App.$store.commit('oCurrentChargerToVuex', oCharger)

            
        
            if(this.viewChargers)    {
                console.log('oCharger:')
                console.log(oCharger)
            }

            if(oCharger.restartApp===1) {
                console.log('RESTART APP !!!')
                if(this.viewChargers)    {
                    console.log('RESTART APP !!!')
                }
                this.startRestartApp(oCharger.id)
            }
    
            this.App.isActiveApp            =   oCharger.active
    
            localStorage.isDigital          =   oCharger.digitalClock
            localStorage.isTrial            =   oCharger.trial
            localStorage.trialTo            =   oCharger.trialTo
    
            //this.App.$store.commit('oCurrentChargerToVuex', oCharger)
    
            //this.updateLastConnect(oCharger.id)   to do remove
    
            if(this.viewChargers)  {
                console.log('IS UPDATES ? :: '+oCharger['updates'])
            }
    
            if(parseInt(oCharger['updates'])===0 ) {
                this.countFlags = this.OBJ.count(this.App.$store.state.oFlagsFromThisCharger)
            }
            else    {
                //this.updateCharger('updates', 0, oCharger['id'])  to do remove
    
                if(this.viewChargers)  {
                    console.log('UPDADES APP :: '+oCharger['id'])
                }
            }
    
            if(parseInt(localStorage.devGlobalInt)!==(parseInt(oCharger['globalInterval'])*60000))  {
                localStorage.devGlobalInt = parseInt(oCharger['globalInterval'])*60000
            }
    
            if(parseInt(localStorage.devControlInt)!==(parseInt(oCharger['controlInterval'])*60000))  {
                localStorage.devControlInt = parseInt( oCharger['controlInterval'])*60000
            }        
    
            console.log(oCharger)
            this.getTechInfo(oCharger)

            console.log(this.App.$store.state.oTechInfo.serialNo)
            
            

            this.getConnectsForCharger(oCharger)

            /*
            if( parseInt(oCharger['updates'])>0 || videoName.trim().length===0) {
                this.getVideoName(oCharger)
            }
            */
    
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (1)', error, formData)})
}

getConnectsForCharger   =   (oCharger)  =>  {

    console.log('START getConnectsForCharger')
    let formData = this.SPEC.formData(this.axios)
    formData.append('command',      'proc-getConnectsForCharger')    
    formData.append('chargerId',    oCharger.id)    

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        console.log(response.data)
        if(!this.SPEC.isErrorResponseData('AsyncData.js (2)', formData, response, true, true))  {
            
            this.App.$store.commit('oChargerConnectsToVuex', response.data)
            //--------------------------VIDEO
            let videoName = this.App.$store.state.promoVideo

            if(this.viewChargers)  {    
                console.log('videoId :: ' + oCharger['videoId'])
                console.log('videoName :: ' + videoName)
            }    

            if( parseInt(oCharger['updates'])>0 || videoName.trim().length===0) {
                this.getVideoName(oCharger)
            }    
            //--------------------------END VIDEO            
        }
    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (2)', error, formData)})


}

getVideoName    =   (oCharger)  =>  {

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getPromoVideo')    
    let fields = '{"serialNo":"'+oCharger['serialNo']+'"}'
    formData.append('fields',  fields)    
    
    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (3)', formData, response, true, true))  {

            this.App.$store.commit('promoVideoToVuex', response.data['promo_video'])
            if(this.viewChargers)  { 
                console.log('VIDEO READ :: ' + this.App.$store.state.promoVideo)
            }   
    
            if(this.countFlags===0 ||  parseInt(oCharger['updates'])>0) {
                this.flagsDataRead(oCharger)
            }            
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (3)', error, formData)})
}

flagsDataRead   =   (oCharger) =>  {    
    
    if(this.viewStartFunc)  console.log('START flagsDataRead()')
    

    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-getCurrentFlags')    
    formData.append('fields', '{"chargerId":"'+oCharger['id']+'"}' )
    //console.log(oCharger['id'])

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        console.log(response.data)
        if(!this.SPEC.isErrorResponseData('AsyncData.js (4)', formData, response, false, true))    {

            this.App.$store.commit('oFlagsFromThisChargerToVuex', response.data)

            if(this.viewFlags)  {console.log(response.data)}
    
            this.slidersDataRead(oCharger)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (4)', error, formData)})
}

slidersDataRead =   (oCharger)  =>  {
    if(this.viewStartFunc)  console.log('START slidersDataRead()')

    let formData = this.SPEC.formData(this.axios)
    formData.append('command', 'proc-getSlidersActiveOnly')    
    formData.append('fields', '{"chargerId":"'+oCharger['id']+'"}')    

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (5)', formData, response, true, false))  {

            if(this.viewSliders)    {console.log(response.data)}

            this.App.$store.commit('oSlidersToVuex', response.data)
            //console.log(oCharger)
            //console.log(oCharger.serialNo)
            
            this.regionCMSManageRead(oCharger)        
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (5)', error, formData)})
}

regionCMSManageRead   =   (oCharger) =>  {

    if(this.viewStartFunc)  console.log('START regionCMSManageRead()')
    
    let identify = 'region-'+localStorage.projectName+'-'+oCharger['serialNo'].substring(0,5)

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getManagementContent') 
    let fields  =   '{'
    fields      +=   '"identify":"'+identify+'", '
    fields      +=   '"tablePrefix":"'+localStorage.projectName+'"}'
    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (6)', formData, response, true, false))  {

            if(this.viewCMS)    console.log(response.data)

            this.App.$store.commit('oRegionManageCMSToVuex', response.data)
            this.regionCMSLinesRead(oCharger)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (6)', error, formData)})
}

regionCMSLinesRead  =   (oCharger)  =>  {

    if(this.viewStartFunc)  console.log('START regionCMSLinesRead()')

    let identify = 'region-'+localStorage.projectName+'-'+oCharger['serialNo'].substring(0,5)
    
    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getLinesContent') 
    let fields  =   '{'
    fields      +=   '"identify":"'+identify+'", '
    fields      +=   '"tablePrefix":"'+localStorage.projectName+'"}'
    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (7)', formData, response, true, false))  {

            if(this.viewCMS)    {console.log(response.data)}
       
            this.App.$store.commit('oRegionLinesCMSToVuex', response.data)
            this.regionCMSInterTextsRead(oCharger)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (7)', error, formData)})
}

regionCMSInterTextsRead =   (oCharger)  =>  {

    if(this.viewStartFunc)  console.log('START regionCMSInterTextsRead()')

    let identify = 'region-'+localStorage.projectName+'-'+oCharger['serialNo'].substring(0,5)

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',  'proc-getInterTexts') 
    let fields  =   '{'
    fields      +=   '"identify":"'+identify+'", '
    fields      +=   '"tablePrefix":"'+localStorage.projectName+'"}'
    formData.append('fields',   fields)   

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {

        if(!this.SPEC.isErrorResponseData('AsyncData.js (8)', formData, response, true, false))  {
            if(this.viewCMS)    {console.log(response.data)}        
       
            this.App.$store.commit('oRegionInterTextsCMSToVuex', response.data)
    
            //TO DO !!!!!!!!!!!!
            //console.log(oCharger)
            
            this.App.isAsyncDataRead = new Date;
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (8)', error, formData)})  

}

/*  to do remove
updateCharger   =   (fieldName, value, chargerId)  =>  {
    let formData = this.SPEC.formData()
    formData.append('command',  'update')
    formData.append('tables',   'chargers')
    formData.append('fields',   '{"'+fieldName+'":"'+value+'"}')
    formData.append('where',    'id='+chargerId)
    axios.post(localStorage.restAPI, formData).then(response => {
        this.SPEC.writeToLogIfError (response.data, formData)
    })
}
*/

getTechInfo   =   (oCurrentCharger)  =>  {

    console.log('getTechInfo')
    //if(this.viewStartFunc)  console.log('getTechInfo :: ' + this.DT.timeFromTimeStamp())

    let formData = this.SPEC.formData(this.axios)
    ///formData.append('command', 'ip')
    formData.append('command', 'proc-getCurrentIP')

    let oTechInfo = []

    console.log(localStorage.restAPI)

    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        console.log(response.data)
        if(!this.SPEC.isErrorResponseData('AsyncData.js (9)', formData, response, true, true))  {
            let isAdminIP = false
            for(let ip of response.data['oAdminsIP'])   {
                if(ip['admin-IP'].toString().trim()===response.data['current-ip'].toString().trim())    {
                    isAdminIP = true
                    break
                }
            }
            

            
            oTechInfo ={    "debugger":         oCurrentCharger['debugger'],
                            "currentIP":        response.data['current-ip'],
                            "currentHostAddr":  response.data['current-host-addr'],
                            "adminIP":          response.data['admin-ip'],
                            "isAdminIP":        isAdminIP,
                            "serialNo":         oCurrentCharger['serialNo'],
                            "axiosPath":        localStorage.restAPI}
    
                            console.log(this.$store.state.oTechInfo.serialNo)                            

            //if(this.viewResponse)   console.log(oTechInfo)
            this.App.$store.commit('oTechInfoToVuex', oTechInfo)
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (9)', error, formData)})
}

startRestartApp  =   (chargerId)  =>  {

    let formData = this.SPEC.formData(this.axios)
    formData.append('command',      'proc-setRestartApp')
    formData.append('chargerId',    chargerId)

    //formData.append('command',  'update')
    //formData.append('tables',   'chargers')
    //formData.append('fields',   '{"restartApp":0}')
    //formData.append('where',    'id='+chargerId)
    
    var App = this.App
    axios.post(localStorage.restAPI, formData).then(response => {
        
        if(!this.SPEC.isErrorResponseData('AsyncData.js (10)', formData, response, true, true))  {

            if(typeof response.data.restartApp==='string')
            if(parseInt(response.data.restartApp)===1)
                this.LIB.restartApp()  
        }

    }).catch(function(error) {new SPEC(App).axiosErrorCatch('AsyncData.js (10)', error, formData)})
}

/*  to do remove
updateLastConnect   =   (chargerId)  =>  {
    let formData = this.SPEC.formData()
    formData.append('command',  'update')
    formData.append('tables',   'chargers')
    formData.append('fields',   '{"lastConnect":"' + this.DT.stdFromJSDateTime(new Date()) + '"}')
    formData.append('where',    'id='+chargerId)

    axios.post(localStorage.restAPI, formData).then(response => {
        this.SPEC.writeToLogIfError (response.data, formData)
    })
}
*/

}