export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["maďarčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["hu"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Töltő modult keresek a márkámhoz - minimum 3 karakter"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás megtalálja a gépe márkájával kompatibilis töltőmodult"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás csak legalább három karakter beírása után kezdi meg a kompatibilis töltőmodul keresését"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A keresett elektromos gép jelenleg nem található az alkalmazáskatalógusban"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["További információ a WEB oldalon"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az időjárás előrejelzés jelenleg nem elérhető !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Óránkénti időjárás előrejelzés"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szélirány"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az eső"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szélsebesség"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Széllökések"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Levegő nyomás"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Havazik"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napkelte"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napnyugta"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["napos"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["félig tiszta"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["felhős"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["szinte felhős"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["teljesen felhős"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő inaktív vagy szervizelés alatt áll"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VISSZA"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TÉRKÉP"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["NYELVEK"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["AZ IDŐJÁRÁS"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO RÉGIÓ"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["CSATLAKOZÓ"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["SEGÍTSÉG"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Alkalmazás menü"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás ezen része átmenetileg nem érhető el"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő jelenleg nem támogatja a megosztott WIFI-t"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Az alkalmazás működéséhez minimális felbontású kijelző szükséges: 800 szélesség és 600 pixel magasság"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vasárnap"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hétfő"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kedd"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szerda"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Csütörtök"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Péntek"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szombat"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Január"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Február"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Március"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Április"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Lehet"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Június"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Július"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Augusztus"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Szeptember"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Október"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["November"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["A töltő tulajdonosa technikai okokból úgy döntött, hogy nem osztja meg a nyilvános WIFI hálózatot"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Megosztott WIFI hálózat áll rendelkezésre a töltő közelében"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Belépés"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jelszó"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Elnézést, a tartalom előkészítése folyamatban van."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sajnáljuk,~de ehhez a töltőhöz jelenleg nem áll rendelkezésre tájékozódási térkép"])}
  }
}