
import OBJ      from    "@/assets/modules/OBJMethodsClass"
import DT       from    "@/assets/modules/DateTime"
export default class dateTime {

constructor(App) {
    this.App            =   App

    this.OBJ            =   new OBJ(this.App)
    this.DT             =   new DT(this.App)

    this.headHeight     =   70
    this.slidersHeight  =   320
}    



getHeightContent    =   (isSliders=true)    =>  {

    let height  =   ''
    height      =   (screen.height-this.headHeight)

    if(isSliders)   
        height  -=   this.slidersHeight
        
    return  height
}

screenSetup =   (viewAdvertising)   =>      {
    this.App.viewAdvertising=viewAdvertising
    this.App.heightContent = this.getHeightContent(viewAdvertising).toString() + 'px',
    this.App.marginTopMsgOff=(((this.getHeightContent(viewAdvertising)-140))/2).toString() + 'px',
    this.App.heightMsgOff='70px'                
}

restartApp  =   ()  =>  {
    //viewAlert=true

    //if(viewAlert)
        //alert('setRestartApp()')

    let aURL = window.location.href.split('/')
    let URL = aURL[0] + '/' + aURL[1] + '/' + aURL[2]

    URL += '/?&' + localStorage.gate + '=' + localStorage.netHeavenlyGate
    URL += '&' + localStorage.net + '=' + localStorage.projectName
    URL += '&timestamp=' + new Date()
    window.location.href = URL
}

set =   (param, delay=0) =>  {

    if(delay===0)
        this.App.$router.push('/'+param)    
    else
        setTimeout(() => {
            this.App.$router.push('/'+param)    
        }, delay);    
}

getCurrentLocale    =   ()  =>  {
    return  localStorage.currentLocale 
}

getPathForFlags     =   ()  =>  {
    return  localStorage.serverPath + localStorage.flagsPath
}

getPathForSlider    =   ()  =>  {
    return  localStorage.dataPath
}

getPathForSVGFlags     =   ()  =>  {
    return localStorage.dataPath + localStorage.flagsSVGPath
}

comeToMenu     =   ()  =>  {
    //history.back()
    this.App.$router.push('/menu')
}

capitalize     =    (param='')  =>  {
    let returnString = param.charAt(0).toUpperCase() + param.substring(1, param.trim().length).toLowerCase()
    return returnString
}

computeHeightScreenApp   =   (difference=0, isSlider=true, notSlider=2)  =>  {

    //let slider = isSlider ? 322: 2

    if(this.OBJ.count(this.App.$store.state.oSliders)===0) isSlider = false
    
    let slider = isSlider ? 322: notSlider
    
    let height = (screen.height - (slider+50))

    if(navigator.userAgent.indexOf('OPR/')>(-1))  
        height -= difference

    return height + 'px'
}

computeMarginTopMessage =   (difference=0, plus=0)  =>  {

    let height

    height = (screen.height - (322+50))

    if(navigator.userAgent.indexOf('OPR/')>(-1))  
        height -= difference

    let returnValue = (((height-150)/2)+plus)

    if(this.OBJ.count(this.App.$store.state.oSliders)===0) 
        returnValue += 80

    return  returnValue + 'px'
}

computeHeightMap    =   (difference=0, minus=0) =>  {
    let height = (screen.height - (322+50))

    if(navigator.userAgent.indexOf('OPR/')>(-1))  
        height -= difference

    return (height-minus) + 'px'
}

}