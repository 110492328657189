export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["holandčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["nl"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Ik ben op zoek naar een oplaadmodule voor mijn merk - minimaal 3 tekens"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De applicatie kan voor u een oplaadmodule vinden die compatibel is met het merk van uw machine"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pas na het invoeren van minimaal drie tekens begint de applicatie met het zoeken naar een compatibele oplaadmodule"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De elektrische machine die u zoekt, staat momenteel niet in de toepassingscatalogus"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Meer informatie op de WEB-pagina"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De weersvoorspelling is momenteel niet beschikbaar !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Weersvoorspelling per uur"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windrichting"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De regen"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windsnelheid"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Windvlagen"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Luchtdruk"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sneeuwen"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zonsopkomst"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zonsondergang"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["zonnig"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["half duidelijk"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bewolkt"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bijna bewolkt"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["helemaal bewolkt"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De oplader is inactief of wordt gerepareerd"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["RUG"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["KAART"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["TALEN"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HET WEER"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["INFO REGIO"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WIFI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["VERBINDER"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["HULP"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Toepassingsmenu"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dit deel van de applicatie is tijdelijk niet beschikbaar"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De oplader ondersteunt momenteel geen gedeelde wifi"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Voor zijn functionaliteit heeft de applicatie een beeldscherm nodig met een minimale resolutie: breedte 800 en hoogte 600 pixels"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zondag"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maandag"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dinsdag"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Woensdag"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Donderdag"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Vrijdag"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zaterdag"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Januari"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Februari"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maart"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["april"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kunnen"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juni"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["juli"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["augustus"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["september"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["oktober"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["november"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["December"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["De eigenaar van de oplader besloot om technische redenen het openbare wifi-netwerk niet te delen"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["In de buurt van de oplader is een gedeeld wifi-netwerk beschikbaar"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Log in"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Wachtwoord"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Sorry, we zijn de inhoud aan het voorbereiden."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Het spijt ons,~maar de oriëntatiekaart voor deze oplader is momenteel niet beschikbaar"])}
  }
}