export default {
  "aktualny-jazyk": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bulharčina"])},
  "ident": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["bg"])},
  "connectors": {
    "find-module": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Търся заряден модул за моята марка - поне 3 знака"])},
    "find-compatible": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложението може да ви намери модул за зареждане, съвместим с марката на вашата машина"])},
    "find-start": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Приложението започва да търси съвместим модул за зареждане само след въвеждане на поне три знака"])},
    "not-found": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Електрическата машина, която търсите, в момента не е намерена в каталога с приложения"])}
  },
  "extend-web": {
    "url": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Повече информация на WEB страницата"])}
  },
  "weather": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Прогнозата за времето в момента не е налична !"])},
    "at-hours": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Почасова прогноза за времето"])},
    "direct-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Посока на вятъра"])},
    "rain": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Дъждът"])},
    "speed-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Скоростта на вятъра"])},
    "gust-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Пориви на вятъра"])},
    "press-win": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Въздушно налягане"])},
    "snow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Сняг"])},
    "sunrise": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["изгрев"])},
    "sunset": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["залез"])},
    "cloudy-1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["cлънчево"])},
    "cloudy-2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["наполовина ясно"])},
    "cloudy-3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["облачно"])},
    "cloudy-4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["почти облачно"])},
    "cloudy-5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["напълно облачно"])}
  },
  "no-active": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Зарядното устройство е неактивно или е в ремонт"])},
  "menu": {
    "back": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ОБРАТНО"])},
    "map": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КАРТА"])},
    "lang": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ЕЗИЦИ"])},
    "weath": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ВРЕМЕТО"])},
    "reg": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ИНФО РАЙОН"])},
    "wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["WI-FI"])},
    "con": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["КОНЕКТОР"])},
    "help": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["ПОМОГНЕ"])},
    "to-menu": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Меню на приложението"])},
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Тази част от приложението е временно недостъпна"])},
    "not-wifi": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["В момента зарядното устройство не поддържа споделен WIFI"])}
  },
  "small-display": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["За своята функционалност приложението изисква дисплей с минимална резолюция: ширина 800 и височина 600 пиксела"])},
  "daysName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hеделя"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Понеделник"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Bторник"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Cряда"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Шетвъртък"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Петък"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Събота"])}
  },
  "monthsName": {
    "0": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Януари"])},
    "1": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Февруари"])},
    "2": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Март"])},
    "3": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aприл"])},
    "4": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Може"])},
    "5": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юни"])},
    "6": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Юли"])},
    "7": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Август"])},
    "8": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Септември"])},
    "9": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Oктомври"])},
    "10": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hоември"])},
    "11": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Декември"])}
  },
  "wifi": {
    "not-allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Собственикът на зарядното реши да не споделя публичната WIFI мрежа по технически причини"])},
    "allow": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Споделена WIFI мрежа е достъпна близо до зарядното устройство"])},
    "login": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Влизам"])},
    "password": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Парола"])}
  },
  "cms": {
    "empty": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съжаляваме, подготвяме съдържанието."])}
  },
  "map": {
    "not-access": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Съжаляваме,~но картата за ориентация на това зарядно устройство не е налична в момента"])}
  }
}